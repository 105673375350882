import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useGlobalState } from 'src/hooks/global-state';

const CategoryTabs = function () {
  const [availableCategories] = useGlobalState('availableCategories');
  const [activeCategory, setActiveCategory] = useGlobalState('activeCategory');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newCategory = availableCategories.find((c) => c.category === newValue);
    setActiveCategory(newCategory);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={activeCategory.category} onChange={handleChange} variant="scrollable">
        {availableCategories.map((cat) => (
          <Tab label={cat.name} value={cat.category} key={cat.category} />
        ))}
      </Tabs>
    </Box>
  );
};

export default CategoryTabs;
