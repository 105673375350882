/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Typography from '@mui/material/Typography';
import Counter from 'src/components/item-counter';
import CommentDialog from 'src/components/item-comment-dialog';
import useItemInformation from 'src/hooks/item-information';
import useItemImages from 'src/hooks/item-images';
import { SxProps } from '@mui/material';
import ItemInfoDialogContainer from 'src/components/item-info-dialog';
import { Item } from 'src/graphql/generated/operations';

const ItemRow = function ({ item }: { item: Item }) {
  const { price } = useItemInformation(item);
  const hasPreview = !!item.preview;
  const images = useItemImages(item);
  const imageStyle: React.CSSProperties = {
    objectFit: 'cover',
    filter: hasPreview ? '' : 'grayscale(1)',
    overflow: 'hidden',
  };
  const itemBarSx: SxProps = {
    top: hasPreview ? '' : 0,
  };

  return (
    <ImageListItem key={item.id}>
      <img
        style={imageStyle}
        src={images[420]}
        srcSet={`${images[420]}, ${images[640]} 1.5x, ${images[840]} 2x`}
        alt={item.name}
        loading="lazy"
      />
      <ImageListItemBar
        position="bottom"
        sx={itemBarSx}
        title={
          <Stack direction="column" spacing={0.5}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography
                variant="h6"
                sx={{ wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: '1.3' }}
              >
                {item.name}
              </Typography>
              <Typography sx={{ lineHeight: '1.3' }} variant="h6">
                {price}:-
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
              <Typography
                data-test-id="item-row-description"
                variant="body2"
                sx={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
              >
                <ItemInfoDialogContainer item={item} mode="text" />
              </Typography>
              <Stack direction="row" spacing={1}>
                <CommentDialog item={item} />
                <Counter item={item} />
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </ImageListItem>
  );
};

export default ItemRow;
