import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useEffect } from 'react';
import {
  IngestionType,
  Item,
  ItemCategory,
  useItemsLazyQuery,
} from 'src/graphql/generated/operations';
import { useGlobalState } from './global-state';

// This file contains a few hooks that act as a fairly thick abstraction on top of a single graph query.
// The purpose of this file is to consolidate tricky logic such as the critera to show/hide an item/category or not
// by simply not returning that item/category if certain criteria are not met.

interface UseItemsResponse {
  items: Item[];
  loading: boolean;
  error: ApolloError;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

interface UseItemCategoriesResponse {
  itemCategories: ItemCategory[];
  loading: boolean;
  error: ApolloError;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

/**
 * Represents all items and item categories for the currently active category.
 * Watches and refetches when they active category changes.
 */
export const useItemsAndItemCategories = () => {
  const [activeCategory] = useGlobalState('activeCategory');
  const [client] = useGlobalState('client');

  const [getItems, { data, loading, error, refetch }] = useItemsLazyQuery({
    variables: {
      category: activeCategory.category,
      client: client.name,
    },
  });

  // Effect that watches activeCategory and clientData, fetches all items for that client and category if either changes.
  // Client will most likely not (ever) change, but category changes as the user navigates from tab to tab.
  useEffect(() => {
    getItems({
      variables: {
        client: client.id,
        category: activeCategory.category,
      },
    });
  }, [activeCategory, client]);

  return { data, loading, error, refetch };
};

/**
 * Represents the items for the currently active category, that are in stock, and has a price for the current ingestion.
 * Also returns whether they're being fetched, and if there was an error fetching them.
 */
export const useItems = (): UseItemsResponse => {
  const [ingestion] = useGlobalState('ingestion');
  const { data, loading, error, refetch } = useItemsAndItemCategories();
  const allItems = data?.items || [];

  const isInStock = (item) => !item.outOfStock;
  const hasPriceForIngestion = (item) => {
    const itemIngestion = item.ingestions.find((i: IngestionType) => i.type === ingestion.type);
    return !!itemIngestion?.price;
  };
  const itemsInStockForIngestion = allItems.filter(isInStock).filter(hasPriceForIngestion);

  return { loading, items: itemsInStockForIngestion, error, refetch };
};

/**
 * Represents the currently available item categories, filtered by currently active category,
 * and to only show item categories that have items for the current ingestion.
 */
export const useItemCategories = (): UseItemCategoriesResponse => {
  const { items } = useItems();
  const { data, loading, error, refetch } = useItemsAndItemCategories();
  const itemCategories = data?.itemCategories || [];

  const inItemCategory = (item: Item, itemCategory: ItemCategory) =>
    item.itemCategory === itemCategory.id;

  const itemCategoryHasItemsForIngestion = (itemCategory: ItemCategory) =>
    items.some((item) => inItemCategory(item, itemCategory));

  // filter out all item categories that do not contain any items
  // that belong to the item category and current ingestion.
  const nonEmptyCategories = itemCategories.filter(itemCategoryHasItemsForIngestion);

  return { loading, itemCategories: nonEmptyCategories, error, refetch };
};
