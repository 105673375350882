/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import Box from '@mui/material/Box';
import ItemRow from 'src/components/item/list';
import { Item } from 'src/graphql/generated/operations';

interface InventoryListProps {
  items: Item[];
}

const InventoryList = function ({ items }: InventoryListProps) {
  return (
    <>
      {items.map((item) => (
        <Box key={item.id} sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <ItemRow item={item} showDescription showAvatar={!!item.preview} />
        </Box>
      ))}
    </>
  );
};

export default InventoryList;
