import * as React from 'react';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'src/hooks/global-state';
import { useCategoryIsOpenForPreorder } from 'src/hooks/category-open';

const OpenForPreorderNotifier = function () {
  const [activeCategory] = useGlobalState('activeCategory');
  const isOpenForPreorder = useCategoryIsOpenForPreorder(activeCategory.category);
  const { t } = useTranslation();

  return (
    isOpenForPreorder && (
      <Alert severity="info" sx={{ borderRadius: 0 }}>
        {t('inventory.openForPreorder', {
          category: activeCategory.name,
          interpolation: { escapeValue: false },
        })}
      </Alert>
    )
  );
};

export default OpenForPreorderNotifier;
