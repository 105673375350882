/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ItemRow from 'src/components/item/gallery';
import { Item } from 'src/graphql/generated/operations';

interface InventoryListProps {
  items: Item[];
}

const InventoryGallery = function (props: InventoryListProps) {
  const { items } = props;
  return (
    <ImageList cols={1} sx={{ mt: 0 }} rowHeight={280} gap={8}>
      {items.map((item) => item && <ItemRow item={item} key={item.id} />)}
    </ImageList>
  );
};

export default InventoryGallery;
