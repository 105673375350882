import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const InventoryPlaceholder = function () {
  const indexes = Array.from(Array(4).keys());

  return (
    <>
      {indexes.map((i) => (
        <Accordion disableGutters square key={i}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack spacing={1}>
              <Skeleton variant="text" width={220} />
            </Stack>
          </AccordionSummary>
        </Accordion>
      ))}
    </>
  );
};

export default InventoryPlaceholder;
