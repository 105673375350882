import { InventoryDisplayMode } from 'src/graphql/generated/operations';
import { useGlobalState } from './global-state';

const useDisplayMode = (): InventoryDisplayMode => {
  const [client] = useGlobalState('client');
  const { inventoryDisplayMode } = client;

  return inventoryDisplayMode || InventoryDisplayMode.Gallery;
};

export default useDisplayMode;
