import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import PaiditLink from 'src/components/paidit-link';
import { usePaiditCart } from 'src/hooks/paidit-cart';
import Typography from '@mui/material/Typography';

const InventoryFooter = function () {
  const { totalItems } = usePaiditCart();
  const { t } = useTranslation();

  return (
    // the extra margin-bottom is to ensure that items at the end are not hidden under the fixed footer.
    <Box sx={{ mb: 16 }}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          position: 'fixed',
          zIndex: (theme) => theme.zIndex.appBar,
          marginBottom: 'env(safe-area-inset-bottom)',
          bottom: '.66rem',
          mx: 3,
          left: 0,
          right: 0,
        }}
      >
        <PaiditLink href="/checkout">
          <Button
            sx={{ boxShadow: '0px 8px 12px 2px rgba(0,0,0,0.2);' }}
            size="large"
            variant="contained"
            fullWidth
            data-test-id="go-to-checkout"
          >
            <Badge color="secondary" badgeContent={totalItems}>
              <Typography variant="body1">{t('inventory.cta')}</Typography>
            </Badge>
          </Button>
        </PaiditLink>
        <Typography
          sx={{ opacity: 0.6, pt: 0.5, display: 'block', width: '100%', textAlign: 'center' }}
          variant="caption"
        >
          {t('inventory.poweredBy')}
        </Typography>
      </Paper>
    </Box>
  );
};

export default InventoryFooter;
