import * as React from 'react';
import Box from '@mui/material/Box';
import { useItemCategories, useItems } from 'src/hooks/items-and-item-categories';
import ErrorRetry from 'src/components/error-retry';
import Navbar from 'src/components/navbar';
import Tabs from './tabs';
import Footer from './footer';
import InventoryPlaceholder from './inventory-placeholder';
import OpenHoursNotifier from './openhours-notifier';
import InventoryForItemCategory from './inventory-for-item-category';
import OpenForPreorderNotifier from './preorder-notifier';

const Inventory = ({ items, itemCategories }) =>
  itemCategories.map((category) => (
    <InventoryForItemCategory
      key={category.id}
      items={items}
      itemCategory={category}
      expanded={itemCategories.length === 1}
    />
  ));

const ClientInventory = function () {
  const { items, loading: itemsLoading } = useItems();
  const {
    itemCategories,
    loading: itemCategoriesLoading,
    error,
    refetch: refetchItemCatgories,
  } = useItemCategories();
  const showInventory = !itemsLoading && !itemCategoriesLoading && items;

  return (
    <>
      <Navbar>
        <Tabs />
      </Navbar>
      {/* the margin-top pushes items down below the tabs of the navbar */}
      <Box sx={{ marginTop: '48px' }}>
        {!showInventory && <InventoryPlaceholder />}
        {showInventory && <OpenHoursNotifier />}
        {showInventory && <OpenForPreorderNotifier />}
        {showInventory && <Inventory items={items} itemCategories={itemCategories} />}
        {/* We know that itemCategories and items come from the same request so refetching one refetches the other. */}
        {error && <ErrorRetry retry={() => refetchItemCatgories()} />}
      </Box>
      <Footer />
    </>
  );
};

export default ClientInventory;
