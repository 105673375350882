import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InventoryDisplayMode, Item, ItemCategory } from 'src/graphql/generated/operations';
import useDisplayMode from 'src/hooks/display-mode';
import InventoryList from './list';
import InventoryGallery from './gallery';

const InventoryForItemCategory = function ({
  items,
  itemCategory,
  expanded,
}: {
  items: Item[];
  itemCategory: ItemCategory;
  expanded: boolean;
}) {
  const [itemsForItemCategory, setItemsForItemCategory] = React.useState([]);
  const displayMode = useDisplayMode();

  React.useEffect(() => {
    const filteredItems = items.filter((item) => item.itemCategory === itemCategory.id);
    setItemsForItemCategory(filteredItems);
  }, [items, itemCategory]);

  let InventoryRenderer;

  switch (displayMode) {
    case InventoryDisplayMode.List:
      InventoryRenderer = InventoryList;
      break;
    default:
      InventoryRenderer = InventoryGallery;
      break;
  }

  // If the category has no entries, don't render anything.
  return (
    itemsForItemCategory.length > 0 &&
    itemCategory && (
      <Accordion
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        key={itemCategory.id}
        defaultExpanded={expanded}
        square
      >
        <AccordionSummary
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            position: 'sticky',
            // 104 is the sum of height of the app bar + tabs beneath it
            top: '104px',
            // Set the z-index just beneath the app bar so the sticky items that get pushed out do not go on top of it.
            // and so that the footer can use the same z-index as the app bar and be on top.
            zIndex: (theme) => theme.zIndex.appBar - 1,
          }}
          expandIcon={<ExpandMoreIcon />}
          id={itemCategory.id}
        >
          <Typography variant="h6">{itemCategory.name}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {itemCategory.description && (
            <Typography
              data-test-id="item-category-description"
              variant="body2"
              component="div"
              sx={{ m: 2 }}
            >
              {itemCategory.description}
            </Typography>
          )}
          <InventoryRenderer items={itemsForItemCategory} />
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default InventoryForItemCategory;
