import * as React from 'react';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'src/hooks/global-state';
import { useCurrentCategoryClosesWithin, useCurrentCategoryIsOpen } from 'src/hooks/category-open';

const OpenHoursNotifier = function () {
  const [activeCategory] = useGlobalState('activeCategory');
  const categoryIsOpen = useCurrentCategoryIsOpen();
  const willCloseWithin = useCurrentCategoryClosesWithin();
  const { t } = useTranslation();

  return (
    <>
      {!categoryIsOpen && (
        <Alert severity="info" sx={{ borderRadius: 0 }}>
          {t('inventory.closed', {
            category: activeCategory.name,
            interpolation: { escapeValue: false },
          })}
        </Alert>
      )}
      {categoryIsOpen && willCloseWithin && willCloseWithin <= 30 && (
        <Alert severity="warning" sx={{ borderRadius: 0 }}>
          {t('inventory.closingSoon', {
            category: activeCategory.name,
            minutes: willCloseWithin,
            interpolation: { escapeValue: false },
          })}
        </Alert>
      )}
    </>
  );
};

export default OpenHoursNotifier;
